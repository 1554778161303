<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="header">
      <logo></logo>
    </div>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t('page-not-found.title') }}</h2>
        <p class="mb-2">
          {{ $t('page-not-found.description') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          {{ $t('back-home') }}
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';
import Logo from '../components/Logo.vue';

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not_found.svg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not_found.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.header {
  position: absolute;
  top: 20px;
  left: 0;
}
</style>
